// @flow
import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import Menu from './Menu';
import Logo from '../images/logo-albrecht-mink.svg';
import '../css/header.scss';

type HeaderProps = {
	siteTitle: string,
};

const Header = (siteTitle: HeaderProps) => (
	<header className="header">
		<TransitionLink className="header__logo" to="/">
			<Logo />
		</TransitionLink>
		<Menu />
	</header>
);

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
