// @flow
import React, { useState, createRef } from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';
import '../css/menu.scss';

const Menu = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [listHeight, setListHeight] = useState(0);
	const list = createRef();

	const handleClick = (e) => {
		e.preventDefault();

		setIsOpen(!isOpen);
		setListHeight(isOpen ? 0 : list.current.getBoundingClientRect().height);
	};

	const menuClassname = isOpen ? 'menu__container menu__container--open' : 'menu__container';
	const menuToggleClassname = isOpen ? 'menu__toggle menu__toggle--open' : 'menu__toggle';

	return (
		<>
			<div className="menu">
				<button className={menuToggleClassname} onClick={handleClick} />
				<a href="mailto:mink@albrechtmink.com">Kontakt</a>
			</div>
			<nav className={menuClassname} style={{height: listHeight}}>
				<ul className="menu__list" ref={list}>
					<li className="menu__item separator separator--bottom">
						<TransitionLink to="/agentur">Agentur</TransitionLink>
					</li>
					<li className="menu__item separator separator--bottom">
						<TransitionLink to="/projekte">Projekte</TransitionLink>
					</li>
					<li className="menu__item separator separator--bottom">
						<TransitionLink to="/kontakt">Kontakt</TransitionLink>
					</li>
					<li className="menu__item separator separator--bottom">
						<TransitionLink to="/datenschutz">Datenschutz</TransitionLink>
					</li>
					<li className="menu__item">
						<TransitionLink to="/impressum">Impressum</TransitionLink>
					</li>
				</ul>
			</nav>
		</>
	);
};

export default Menu;
