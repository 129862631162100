// @flow
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import CookieConsent from 'react-cookie-consent';
import Header from './Header';
import Footer from './Footer';
import '../css/layout.scss';
import '../css/separator.scss';
import '../css/footer.scss';
import '../css/cookiemessage.scss';

type LayoutProps = {
	children: React.Node,
	withFooter: boolean,
};

const Layout = (props: LayoutProps) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="container">
      <Header siteTitle={data.site.siteMetadata.title} />
			<main>{props.children}</main>
			{props.withFooter && (<Footer />)}
			<CookieConsent
				containerClasses="cookiemessage"
				contentClasses="cookiemessage__content"
				buttonClasses="cookiemessage__button"
				location="bottom"
				buttonText="Akzeptieren"
				disableStyles
			>
				Wir verwenden Cookies, um die korrekte Funktionsweise einer Website zu gewährleisten, zur Reichweitenmessung und um die Benutzerfreundlichkeit zu verbessern.
				Informationen über Cookies und deren Deaktivierung finden Sie in unserer <Link to="datenschutz">Datenschutzerklärung</Link>.
				Durch die Nutzung unserer Website stimmen Sie der Verwendung von Cookies zu.
			</CookieConsent>
    </div>
  );
};

Layout.defaultProps = {
	withFooter: false,
};

export default Layout;
