// @flow
import React from 'react';
import TransitionLink from 'gatsby-plugin-transition-link';

const Footer = (props) => (
	<footer className="footer separator separator--top">
		<TransitionLink to="kontakt">Kontakt</TransitionLink>
		<nav className="footer__nav">
			<TransitionLink to="datenschutz">Datenschutz</TransitionLink>
			<span className="footer__spacer">|</span>
			<TransitionLink to="impressum">Impressum</TransitionLink>
		</nav>
	</footer>
)
export default Footer;
